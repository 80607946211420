exports.components = {
  "component---src-components-blog-category-list-js": () => import("./../../../src/components/blog-categoryList.js" /* webpackChunkName: "component---src-components-blog-category-list-js" */),
  "component---src-components-blog-post-js": () => import("./../../../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-company-history-js": () => import("./../../../src/pages/company-history.js" /* webpackChunkName: "component---src-pages-company-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-market-js": () => import("./../../../src/pages/market.js" /* webpackChunkName: "component---src-pages-market-js" */),
  "component---src-pages-news-room-js": () => import("./../../../src/pages/news-room.js" /* webpackChunkName: "component---src-pages-news-room-js" */),
  "component---src-pages-our-mission-js": () => import("./../../../src/pages/our-mission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-smart-fish-market-js": () => import("./../../../src/pages/smart-fish-market.js" /* webpackChunkName: "component---src-pages-smart-fish-market-js" */),
  "component---src-pages-wholesale-price-js": () => import("./../../../src/pages/wholesale-price.js" /* webpackChunkName: "component---src-pages-wholesale-price-js" */)
}

